'use client';

import { Badge } from '@formo/ui';
import { FC } from 'react';
import { useAuth } from '~/app/hooks/AuthProvider';
import useDashboard from '~/app/hooks/useDashboard';
import useMemberList from '~/app/hooks/useMemberList/useMemberList';
import { Dialog, DialogContent } from '~/components/ui/dialog';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '~/components/ui/dropdown-menu';
import { Popover, PopoverTrigger } from '~/components/ui/popover';
import { TEAM_ACTIONS, TEAM_ROLES } from '~/constants/permission';

import { Avatar, VerticalEllipsis } from '../../common';
import ActionModal from './ActionModal';
import RoleSelect, { RoleOption } from './RoleSelect';

type MemberProps = {
  id: string;
  email: string;
  role: string;
  isPendingRole?: boolean;
  onChangeRole?: (userId: string, role: TEAM_ROLES) => void;
  availableRoles: RoleOption[];
  isInvite?: boolean;
};

const Member: FC<MemberProps> = ({
  id,
  email,
  role,
  isPendingRole = false,
  onChangeRole,
  availableRoles,
  isInvite = false,
}) => {
  const { user: currentUser } = useAuth();
  const { permissions, activeTeam } = useDashboard();

  const {
    onResendInvite,
    setPopup,
    isLoading,
    onRemoveMember,
    onCancelInvite,
    shouldShowPopup,
    onTransferOwnership,
  } = useMemberList();

  const isPopoverOpen = shouldShowPopup(id, 'popover');

  return (
    <>
      <Popover
        modal={true}
        open={isPopoverOpen}
        onOpenChange={(value) => {
          if (!value) setPopup(null);
        }}
      >
        <div className="flex items-center border-t border-black/5 py-3 pl-4 pr-2">
          {!isInvite && <Avatar name={email?.[0]?.toUpperCase()} />}

          <div className="ml-2.5 min-w-0">
            <div className="flex gap-1.5 items-center">
              <span
                className={`min-w-0 overflow-clip text-ellipsis text-sm font-normal ${isInvite && 'text-content-tertiary'}`}
              >
                {email}
              </span>
              {id === currentUser?.id && (
                <Badge type="light" color="blue">
                  Me
                </Badge>
              )}
              {isInvite && (
                <Badge type="light" color="gray">
                  Invite pending
                </Badge>
              )}
            </div>
          </div>
          <RoleSelect
            onValueChange={(value: TEAM_ROLES) => onChangeRole?.(id, value)}
            value={role}
            disabled={
              id === currentUser?.id ||
              role === TEAM_ROLES.OWNER ||
              !permissions.includes(TEAM_ACTIONS.MEMBERS_MANAGEMENT) ||
              isPendingRole
            }
            label={
              role === TEAM_ROLES.OWNER
                ? 'Owner'
                : availableRoles.find((r) => r.value === role)?.label
            }
            roles={availableRoles}
            classNames={{
              trigger:
                'ml-auto w-[104px] border justify-between border-solid flex-none h-[34px] text-sm font-normal',
            }}
          />

          {/* 
            Only show the vertical ellipsis icon if the user has the permission to manage members, not the owner of the team, and the member is not the current user
           */}
          {permissions.includes(TEAM_ACTIONS.MEMBERS_MANAGEMENT) &&
          id !== currentUser?.id &&
          role !== TEAM_ROLES.OWNER ? (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <PopoverTrigger>
                  <VerticalEllipsis
                    size={20}
                    color="rgba(142,142,146)"
                    className="ml-1 flex-shrink-0 cursor-pointer transition-colors hover:text-green-900"
                  />
                </PopoverTrigger>
              </DropdownMenuTrigger>
              <DropdownMenuContent
                align="end"
                sideOffset={12}
                className="border-[0.5px] border-black/10 p-1 !shadow-elevation-below"
              >
                {isInvite ? (
                  <>
                    <DropdownMenuItem
                      className="cursor-pointer px-[14px] py-2.5"
                      onClick={() => onResendInvite(id, email)}
                      disabled={isLoading}
                    >
                      Resend
                    </DropdownMenuItem>
                    <DropdownMenuItem
                      className="cursor-pointer px-[14px] py-2.5"
                      onClick={() => onCancelInvite(id, email)}
                      disabled={isLoading}
                    >
                      Remove
                    </DropdownMenuItem>
                  </>
                ) : (
                  <>
                    {permissions.includes(TEAM_ACTIONS.TRANSFER_OWNERSHIP) && (
                      <DropdownMenuItem
                        className="cursor-pointer px-[14px] py-2.5"
                        disabled={isLoading}
                        onClick={() => {
                          setPopup({
                            id,
                            mode: 'transfer',
                            type: 'modal',
                          });
                        }}
                      >
                        Transfer ownership
                      </DropdownMenuItem>
                    )}
                    {role !== TEAM_ROLES.OWNER && (
                      <DropdownMenuItem
                        className="cursor-pointer px-[14px] py-2.5"
                        onClick={() => onRemoveMember(id, email)}
                        disabled={isLoading}
                      >
                        Remove
                      </DropdownMenuItem>
                    )}
                  </>
                )}
              </DropdownMenuContent>
            </DropdownMenu>
          ) : (
            <div className="ml-1 aspect-auto w-5 flex-shrink-0"></div>
          )}
        </div>
        {/* 24px is the vertical ellipsis icon (20px) + ml-1 (4px); any number higher than this does nothing */}
      </Popover>
      <Dialog
        open={shouldShowPopup(id, 'modal')}
        onOpenChange={(value) => {
          if (!value) setPopup(null);
        }}
      >
        <DialogContent
          classNames={{ close: 'hidden' }}
          className="max-w-3xl gap-0 rounded-lg"
        >
          {shouldShowPopup(id, 'modal', 'transfer') && (
            <ActionModal
              title="Transfer ownership"
              description={
                <>
                  Certain that you want to make{' '}
                  <span className="font-semibold">{email}</span> the owner of{' '}
                  {activeTeam?.team.teamName}? You cannot undo this action
                </>
              }
              confirmText="Transfer ownership"
              onConfirm={() => onTransferOwnership(id, email)}
              cancelText="Cancel"
              onCancel={() => setPopup(null)}
            />
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export const MemberSkeleton = () => {
  return (
    <div className="flex items-center border-t border-black/5 py-3 pl-4 pr-2">
      <div className="aspect-square h-8 animate-pulse rounded-full border border-[rgba(112,125,98,0.2)] bg-black/20"></div>
      <div className="ml-2.5 h-6 w-52 animate-pulse rounded-md bg-black/20"></div>
      <RoleSelect
        disabled
        roles={[]}
        classNames={{
          trigger:
            'ml-auto w-max border border-solid flex-none h-[34px] w-20 text-transparent animate-pulse bg-black/20',
        }}
      />
      <div className="ml-1 aspect-auto w-5 flex-shrink-0"></div>
    </div>
  );
};

export default Member;
