import { SelectProps } from '@radix-ui/react-select';
import { FC } from 'react';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
} from '~/components/ui/select';
import { TEAM_ROLES } from '~/constants/permission';
import { cn } from '~/lib/utils';

export type RoleOption = {
  label: string;
  value: TEAM_ROLES;
  subtitle: string;
};

type RoleSelectProps = SelectProps & {
  classNames?: {
    trigger?: string;
    content?: string;
    item?: string;
  };
  roles: RoleOption[];
  label?: string;
};

const RoleSelect: FC<RoleSelectProps> = ({
  classNames,
  roles,
  value: role,
  label,
  ...props
}) => {
  return (
    <Select {...props} value={role}>
      <SelectTrigger
        className={cn(
          'h-full min-w-min flex-1 gap-2 border-none focus:ring-transparent focus:ring-offset-0 focus-visible:ring-transparent focus-visible:ring-offset-0',
          classNames?.trigger,
        )}
        type="button"
      >
        {label}
      </SelectTrigger>
      <SelectContent
        className={cn(
          '!rounded-sm border-1 border-content-border bg-surface-primary shadow-[0px_2px_8px_0px_rgba(23,25,35,0.12)] backdrop-blur-sm p-1',
          classNames?.content,
        )}
        align="end"
        alignOffset={-1.5}
      >
        {roles.map(({ value, label, subtitle }) => (
          <SelectItem
            key={value}
            value={value}
            className={cn(
              'cursor-pointer p-2 transition-colors focus:bg-surface-hover !rounded-sm h-14 flex flex-col gap-1 items-start justify-center',
              classNames?.item,
            )}
          >
            <div className="b3">{label}</div>
            <div className="s1 text-content-secondary">{subtitle}</div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};

export default RoleSelect;
