import React, { FC, HTMLAttributes } from 'react';
import { Button } from '~/components/ui/button';
import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '~/components/ui/dialog';
import { cn } from '~/lib/utils';

type ActionModalProps = {
  title: React.ReactNode;
  description: React.ReactNode;
  onConfirm: () => void;
  onCancel: () => void;
  confirmText?: string;
  cancelText?: string;
  confirmClassName?: HTMLAttributes<HTMLButtonElement>['className'];
};

const ActionModal: FC<ActionModalProps> = ({
  title,
  description,
  onCancel,
  onConfirm,
  confirmText = 'Confirm',
  cancelText = 'Cancel',
  confirmClassName,
}) => {
  return (
    <>
      <DialogHeader className="space-y-2">
        <DialogTitle className="b1 font-semibold text-black">
          {title}
        </DialogTitle>
        <DialogDescription className="b3 font-normal text-content-primary">
          {description}
        </DialogDescription>
      </DialogHeader>
      <DialogFooter className="mt-[18px] flex justify-end gap-3">
        <Button variant="ghost" onClick={onCancel}>
          {cancelText}
        </Button>
        <Button
          className={cn(
            'text-black ring-[1.5px] ring-inset ring-green-400',
            confirmClassName,
          )}
          onClick={onConfirm}
        >
          {confirmText}
        </Button>
      </DialogFooter>
    </>
  );
};

export default ActionModal;
